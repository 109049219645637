import React from 'react';
import { graphql } from 'gatsby';

import {
	Layout,
	Hero,
	Container,
	Grid,
	SnusBoxList,
	NumberedContent,
	Spacer,
	Separator,
} from '../components';

const renderListItem = (row) => (
	<div>
		<Grid align="middle-xs">
			{row.description && row.description !== ' ' && (
				<Grid.Col width="xs-14,md-12">
					<p className="f-large color-black-light">
						{row.description}
					</p>
				</Grid.Col>
			)}
			<Grid.Col width="xs-10,sm-4">
				<img src={row.logo} loading="lazy" alt="Logo" />
			</Grid.Col>
		</Grid>
		<SnusBoxList colWidth="xs-12,sm-6,lg-4" data={row.products} cols={5} />
	</div>
);

const BrandsPage = ({
	pageContext,
	data: {
		wordpressPage: { acf: translations },
	},
}) => {
	return (
		<Layout
			title={translations.seo_title}
			description={translations.seo_description}
			image={translations.seo_cover_image}
			pageContext={pageContext}
		>
			<Hero
				image={translations.brands_background_image}
				title={translations.brands_title}
				text={translations.brands_descriptions}
			/>
			<Container left={236}>
				{translations.brands_brands.map((row, i) => (
					<React.Fragment key={i}>
						{i !== 0 && <Separator />}
						<Spacer desktop={i === 0 ? 60 : 30} mobile={50} />
						<NumberedContent
							number={i + 1}
							title={row.title}
							indent
						>
							{renderListItem(row)}
						</NumberedContent>
						<Spacer desktop={60} mobile={30} />
					</React.Fragment>
				))}
			</Container>
			<Spacer desktop={60} mobile={40} />
		</Layout>
	);
};

export default BrandsPage;

export const query = graphql`
	query($id: Int, $lang: String) {
		wordpressPage(
			wordpress_id: { eq: $id }
			wpml_current_locale: { eq: $lang }
		) {
			wpml_current_locale
			acf {
				seo_title
				seo_description
				seo_cover_image
				brands_title
				brands_descriptions {
					description
				}
				brands_background_image
				brands_brands {
					title
					description
					logo
					products {
						title
						subtitle
						image
					}
				}
			}
		}
	}
`;
